button svg {
  width: 0.875rem;
  height: 0.875rem;
}

/* --- PRIMARY --- */
.button-primary {
  background: linear-gradient(180deg, #8388f4 0%, #0b0d3e 100%) !important;
  color: #fff;
}
.button-primary > .button-inner {
  background: var(--primary-gradient) !important;
}
.button-primary:focus > .button-inner {
  background: linear-gradient(180deg, #3a3ea6 0%, #6065ea 100%) !important;
}
.button-primary:disabled {
  opacity: 0.5;
}
.button-primary:disabled > .button-inner {
  background: linear-gradient(180deg, #6065ea 0%, #3a3ea6 100%) !important;
  cursor: not-allowed;
}

/* --- SECONDARY --- */
.button-secondary {
  background: linear-gradient(180deg, #f3f3f3 0%, #cfcfcf 100%) !important;
}
.button-secondary > .button-inner {
  background: linear-gradient(180deg, #f5f5f5 0%, #eaeaea 100%) !important;
}
.button-secondary:focus {
  background: linear-gradient(180deg, #c0c0c0 0%, #f3f3f3 100%) !important;
}
.button-secondary:focus > .button-inner {
  background: linear-gradient(180deg, #ededed 0%, #d5d5d5 100%) !important;
}
.button-secondary:disabled > .button-inner {
  opacity: 0.3;
  cursor: not-allowed;
}

/* --- SECONDARY LIGHTEN --- */
.button-secondary-lighten {
  background: linear-gradient(180deg, #f3f3f3 0%, #cfcfcf 100%) !important;
}
.button-secondary-lighten > .button-inner {
  background: linear-gradient(180deg, #fff, #f1f1f1) !important;
}
.button-secondary-lighten:focus {
  background: linear-gradient(180deg, #c0c0c0 0%, #f3f3f3 100%) !important;
}
.button-secondary-lighten:focus > .button-inner {
  background: linear-gradient(180deg, #ededed 0%, #d5d5d5 100%) !important;
}
.button-secondary-lighten:disabled > .button-inner {
  opacity: 0.3;
  cursor: not-allowed;
}

/* --- TERNARY --- */
.button-ternary {
  background: #dedede !important;
}
.button-ternary > .button-inner {
  background: #fff !important;
}
.button-ternary:focus > .button-inner {
  background: #f2f2f2;
}
.button-ternary:disabled > .button-inner {
  opacity: 0.3;
  cursor: not-allowed;
  background: #f2f2f2 !important;
}
