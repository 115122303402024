.custom-select {
  @apply w-full;
}

.custom-select.default .custom-select__control {
  @apply min-h-[2rem];
}
.custom-select.large .custom-select__control {
  @apply min-h-[2.5rem];
}

.custom-select.allow-search .custom-select__control {
  @apply hover:cursor-text;
}

.custom-select.border-danger > .custom-select__control {
  @apply !border-danger focus:!border-danger hover:!border-danger;
}

.custom-select__control {
  transition: 0.2s linear !important;
  @apply text-sm !rounded-lg !border-light-stroke hover:cursor-pointer hover:!border-primary;
}

.custom-select__control--is-focused {
  @apply !border-primary !shadow-none;
}

.custom-select__input-container {
  @apply !mt-0;
}

.custom-select__multi-value {
  @apply !rounded-lg !bg-primary-lighten;
}
.custom-select__multi-value__label {
  @apply !text-xs !text-primary;
}
.custom-select__multi-value__remove {
  @apply w-2 !p-0 ml-1 mr-1.5 inline-flex items-center justify-center hover:!bg-transparent;
}

.custom-select__indicator-separator {
  @apply hidden;
}
.custom-select__indicator {
  @apply !p-0 w-8 items-center justify-center !text-light-primary;
}
.custom-select__clear-indicator {
  @apply w-3 cursor-pointer;
}

.custom-select__menu {
  @apply !mb-0 !rounded-lg border border-light-stroke bg-white;
  box-shadow: 0px 4px 3px rgb(0 0 0 / 15%), 0px 1px 2px rgb(0 0 0 / 25%) !important;
}
.custom-select__menu-list {
  @apply !px-2 !max-h-[13rem] overflow-x-hidden;
}

.custom-select__option {
  @apply !text-[0.8125rem] !cursor-pointer !rounded-lg whitespace-nowrap overflow-hidden text-ellipsis;
}
.custom-select__option--is-focused {
  @apply !bg-light-bg;
}
.custom-select__option--is-selected {
  @apply !bg-primary;
}

.custom-select__menu-list::-webkit-scrollbar {
  @apply w-[10px] h-[10px] bg-transparent;
}
.custom-select__menu-list::-webkit-scrollbar-track {
  @apply bg-transparent;
}
.custom-select__menu-list::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  @apply h-[6px] bg-clip-padding rounded-lg bg-[rgba(0,0,0,0.3)];
}
