.custom-tabs.rc-tabs {
  @apply border-0;
}

.custom-tabs .rc-tabs-nav::after {
  content: '';
  @apply absolute left-0 right-0 bottom-[-1px] w-full border-b border-light-stroke;
}

.custom-tabs .rc-tabs-nav-list {
  @apply gap-8;
}

.custom-tabs.padding-nav-wrap .rc-tabs-tab:first-child {
  @apply !ml-4;
}
.custom-tabs.padding-nav-wrap .rc-tabs-tab:nth-last-child(2) {
  @apply !mr-4;
}

.custom-tabs .rc-tabs-tab {
  @apply mb-3 font-normal select-none;
}

.custom-tabs .rc-tabs-tab .rc-tabs-tab-btn {
  @apply leading-none text-sm;
}

.custom-tabs .rc-tabs-tab.rc-tabs-tab-active {
  @apply text-primary font-normal;
}

.custom-tabs .rc-tabs-ink-bar {
  @apply bg-primary rounded-sm;
}

.custom-tabs .rc-tabs-content-holder,
.custom-tabs .rc-tabs-tabpane {
  @apply outline-0;
}

.custom-tabs .rc-tabs-content-holder {
  @apply mt-px;
}

.custom-tabs .rc-tabs-nav-wrap {
  @apply min-w-[6.25rem];
}

.custom-tabs .rc-tabs-nav-operations,
.custom-tabs .rc-tabs-nav-wrap-ping-right::after,
.custom-tabs .rc-tabs-nav-wrap-ping-left::before {
  @apply hidden;
}

.custom-tabs.modal-tabs .rc-tabs-nav {
  @apply px-[5.3125rem];
}
