@tailwind base;
@tailwind components;
@tailwind utilities;

@import './classes.css';
@import './button.css';
@import './datePicker.css';
@import './pagination.css';
@import './select.css';
@import './slider.css';
@import './table.css';
@import './tabs.css';
@import './toast.css';

:root {
  --primary: #5156d3;
  --info: #55f5ff;
  --success: #0b9830;
  --waring: #ffc123;
  --danger: #cc2020;

  --primary-gradient: linear-gradient(180deg, #6065ea 0%, #3a3ea6 100%);
  --info-gradient: linear-gradient(180deg, #55f5ff 0%, #1dadcd 100%);
  --success-gradient: linear-gradient(180deg, #46d86c 0%, #0b9830 100%);
  --danger-gradient: linear-gradient(180deg, #f96e6e 0%, #cc2020 100%);
}

* {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
