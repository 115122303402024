.menu_item {
  @apply h-[3.125rem] text-[0.875rem];
}

.menu_item.active {
  color: var(--primary);
}

.menu_item.active::before {
  content: '';
  background: var(--primary-gradient);
  border-radius: 2px;
  @apply absolute top-[calc(50%_-_1rem)] left-0 w-[4px] h-[2rem];
}

.logo_expanded {
  @apply w-[7.375rem] h-[2rem];
}

.collapse_menu_item > div:last-child {
  @apply pb-0;
}

.scrollbar_container {
  height: calc(100% - (55px * 2));
}
