.Toastify__toast-container {
  width: fit-content;
}

.Toastify__toast-body {
  margin: 0;
  padding: 0;
}

.Toastify__toast-icon {
  display: none;
}

.custom-toast {
  box-shadow: unset;
  border-radius: 8px;
  padding: 10px;
  max-width: 25rem;
  min-height: fit-content;
}
.custom-toast .title {
  word-break: break-word;
}
.custom-toast .toast-icon svg {
  width: 100%;
  height: 100%;
}

.custom-toast.toast-info {
  background: #d7fbff;
}
.custom-toast.toast-info .title {
  color: #0b2a2e;
}
.custom-toast.toast-info .description {
  color: #336d74;
}

.custom-toast.toast-success {
  background: #d2ffde;
}
.custom-toast.toast-success .title {
  color: #032f0e;
}
.custom-toast.toast-success .description {
  color: #3c6647;
}

.custom-toast.toast-warning {
  background: #ffe8c8;
}
.custom-toast.toast-warning .title {
  color: #372305;
}
.custom-toast.toast-warning .description {
  color: #815922;
}

.custom-toast.toast-error {
  background: #ffe4e4;
}
.custom-toast.toast-error .title {
  color: #320b0b;
}
.custom-toast.toast-error .description {
  color: #634848;
}
