.base-transition {
  transition: 0.2s linear;
}

.bg-primary-gradient {
  background: var(--primary-gradient);
}

.bg-info-gradient {
  background: var(--info-gradient);
}

.bg-success-gradient {
  background: var(--success-gradient);
}

.bg-danger-gradient {
  background: var(--danger-gradient);
}
