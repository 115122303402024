.rc-table {
  @apply text-light-primary text-[0.8125rem];
}

.rc-table-content {
  @apply border-0;
}

.rc-table tr {
  background: linear-gradient(180deg, #f0f0f0, #f9f9f9);
}
.rc-table tr th {
  @apply border-b-0 font-semibold uppercase bg-transparent;
}
.rc-table tr th:first-child {
  @apply rounded-tl-lg;
}
.rc-table tr th:last-child {
  @apply rounded-tr-lg;
}
.rc-table tr th:not(:last-child)::after {
  content: '';
  @apply absolute top-[50%] right-0 w-px h-[70%] bg-[#dedede] translate-y-[-50%];
}

.rc-table th,
.rc-table td {
  transition: 0.2s linear;
  @apply border-r-0 border-light-stroke;
}

.rc-table-cell.rc-table-cell-row-hover {
  @apply bg-primary-lighten/40;
}

.rc-table-expanded-row-fixed::after {
  @apply border-0;
}
