.rc-pagination {
  @apply flex items-center min-h-[2rem] gap-1.5;
}

.rc-pagination-options,
.rc-pagination::after {
  @apply hidden;
}

.rc-pagination-item {
  transition: 0.2s linear;
  @apply min-w-[2rem] min-h-[2rem] m-0 rounded-lg text-[0.8125rem] inline-flex items-center justify-center shrink-0 text-center border-light-stroke;
}

.rc-pagination-item:focus,
.rc-pagination-item:hover,
.rc-pagination-item-active {
  border-color: var(--primary) !important;
}

.rc-pagination-item:focus a,
.rc-pagination-item:hover a,
.rc-pagination-item-active a,
.rc-pagination-item-active a {
  @apply text-primary;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev {
  @apply m-0 shrink-0 min-h-[2rem];
}

.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  @apply min-h-[2rem] inline-flex items-center justify-center items-center m-0;
}

.rc-pagination-prev button:after,
.rc-pagination-next button:after {
  @apply hidden;
}
