.react-datepicker {
  font-family: inherit;
  background: transparent;
  color: inherit;
  border: none;
  border-radius: 0;
}

.react-datepicker__triangle {
  @apply hidden;
}

.react-datepicker__month-container {
  float: unset;
}

.react-datepicker__header {
  @apply border-0 p-0 bg-transparent;
}

.react-datepicker__day-names {
  @apply m-0 mb-2 mt-[1.125rem] flex gap-1.5;
}

.react-datepicker__day-name {
  @apply m-0 w-[1.875rem] h-[1.875rem] text-[0.8125rem] font-medium;
}

.react-datepicker__month {
  @apply m-0;
}

.react-datepicker__week {
  @apply flex gap-1.5;
}
.react-datepicker__week:not(:last-child) {
  @apply mb-1;
}

.react-datepicker__day {
  @apply relative m-0 w-[1.875rem] h-[1.875rem] inline-flex justify-center items-center text-[0.8125rem] text-light-primary select-none;
}

.react-datepicker__day:hover {
  @apply rounded-md bg-light-hover;
}

.react-datepicker__day--keyboard-selected {
  @apply bg-transparent;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--range-end,
.react-datepicker__day--selected {
  background: var(--primary-gradient);
  @apply rounded-md font-medium !text-white;
}

.react-datepicker__day.react-datepicker__day--today {
  background: var(--danger-gradient);
  @apply rounded-md font-medium !text-white;
}

.react-datepicker__day.react-datepicker__day--in-range,
.react-datepicker__day.react-datepicker__day--in-selecting-range {
  @apply bg-[#F5F6FF] text-light-primary;
}

.react-datepicker__day--outside-month {
  @apply text-[#c1c1c1];
}

.double-range-picker .react-datepicker__day--outside-month{  
  @apply cursor-default invisible opacity-0
}